.signup-form {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  background-color: gold;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px black;
}

.signup-input {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 5px;
}

.submit {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  margin-top: 30px;
  border-radius: 10px;
  height: 40px;
  border: none;
  display: block;
  color: white;
}

.sign-out {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  display: block;
  background-color: black;
  border-radius: 10px;
  height: 40px;
  border: none;
  display: block;
  color: white;
}

.header {
  font-size: 30px;
  text-align: center;
  box-shadow: 2px 2px 2px 2px gray;
  padding: 20px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: orange;
  font-size: 10px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
